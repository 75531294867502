.gallery-strip {

    &__image {
        width: 25%;
    }

    img {
        width: 100%;
        height: auto;
        aspect-ratio: attr(width) / attr(height);
    }

    &__back {
        background: #000;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        line-height: 0;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 7.5vh 0;
    }

    &__top-bars {  
        right: 0;
        top: 0;
    }
    &__bottom-bars {         
        left: 0;
        bottom: 0;
        transform: rotate(180deg);
    }


    &__bottom-bars,
    &__top-bars {
        height: 10vh;
        background-image: url('../images/bars.svg');
        background-repeat: no-repeat;
        z-index: -1;
        background-size: cover;
        position: absolute;
        width: 70%;
    }

    @media (max-width: 1200px) {
        &__bottom-bars,
        &__top-bars {
            width: 100%;
        }


        &__content {
            padding: 8vh 0;
        }

        &__image {
            width: 50%;
        }

        &__content {
            padding: 6vh 0;
        }
    } 




}