.carousel {
    $self: &;
    position: relative;
    overflow: hidden;
    padding: 0 20px 125px 20px;
    margin: 0 auto;
    max-width: 1680px;
    overflow: visible;

    .glide__track {
        overflow: visible;
    }

    .glide__slides {
        display: flex;
        align-items: stretch;
        list-style-type: none;
    }

    &__slide {
        padding: 0 10px;
        box-sizing: border-box;
        opacity: 1;
        transition: opacity 0.3s ease, transform 0.1s linear;

        @media (min-width: 768px) {
            &:hover {
                transform: scale(0.95);
                .carousel__image-container {
                    box-shadow: 0 25px 25px #000000e6;
                }

                .carousel__image {
                    opacity: 1;
                }

                .carousel__icon:before {
                    opacity: 1;
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            text-decoration: none;
        }
    }

    &__icon:before,
    &__icon:before {
        font-family: "Fontello", arial;
        display:flex;
        color: #fff;
        font-size: 5rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        transition: all 0.1s linear;
        opacity: 1;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    }

    &__icon-spotify:before {
        content: '\f1bc';
    }

    &__icon-youtube:before {
        content: '\f16a';
    }

    &__image-container {
        position: relative;
        width: 100%;
        padding-top: 100%; // 1:1 Aspect Ratio
        overflow: hidden;
        transition: box-shadow 0.1s linear;
        box-shadow: 0 35px 25px #000000e6;
        background: #000;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.7;
        transition: opacity 0.1s linear;
    }

    &__title {
        margin-top: 10px;
        font-size: clamp(1.2rem, 1.75vw, 1.5rem);
        font-weight: 600;
        text-align: center;
        font-family: $font-main;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 2.5rem;
        text-align: center;

        &:hover,
        &:active,
        &:visited {
            color: #fff;
        }
    }

    &__nav-buttons {
        position: absolute;
        right: 10px;
        bottom: 25px;
        display: flex;
        @media (max-width: 768px) {
            right: 20px;
        }
    }

    &__nav-button {
        border: 2px solid #fff;
        border-radius: 50px;
        cursor: pointer;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: #fff;
        font-size: 2rem;
        font-family: "fontello", Arial;

        &--prev {
            margin-right: 20px;
            &::before {
                content: '\e800';
            }
        }

        &--next {
            &::before {
                content: '\e803';
            }
        }
    }

    // Responsive adjustments
    @media (max-width: 1024px) {
        .glide__slide {
            width: 33.333%; // 3 columns for tablet
        }
    }

    @media (max-width: 768px) {
        .glide__slide {
            width: 50%; // 2 columns for small tablet
        }
    }

    @media (max-width: 480px) {
        .glide__slide {
            width: 100%; // 1 column for mobile
        }
    }

      &__pagination {
        position: absolute;
        bottom: 50px;
        left: 10px;
        display: flex;
        gap: 5px;
        @media (max-width: 768px) {
            left: 20px;
        }
    }

      &__pagination-bullet {

        width: 10px;
        height: 10px;
        background-color: #000;
        border: 2px solid #fff;
        border-radius: 50%;
        cursor: pointer;

        &.active {
            background-color: #fff;
        }
      }
}