.next-event {
  position: relative;
  will-change: background-position; 
  background-size: cover; 
  background-position: center;
  overflow: visible;


  .section__title {
    margin-bottom: 2rem;
  }

  .section__text {
    margin-bottom: 4rem;
  }

  @media (min-width: 768px) {
    .section__content-1st {
      align-items: flex-start;
    }
  }
  @media (max-width: 768px) {
    .section__content-1st {
      margin-bottom: 2rem;
    }
  }

  &--bubble-container {
    position: absolute;
    height: 1000px;
    left: calc(50% + 350px);
    top: -640px;
    width: 900px;
    z-index: -1;
  }

  &--bubble {
    background-image: url('../images/ready/fluid02_1200px.webp');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    transform: rotate(-3deg);
  }


  &--bubble-container-2 {
    position: absolute;
    height: 1200px;
    right: calc(50% + 350px);
    bottom: -240px;
    width: 1000px;
    z-index: -1;

    @media (max-width: 650px) {
      bottom: unset;
      top: 100px;
      left: -280px;
      width: 400px;
      height: 400px;
    }
  }

  &--bubble-2 {
    background-image: url('../images/ready/fluid01_1200px.webp');
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    transform: rotate(-160deg);

    @media (max-width: 650px) {
      transform: rotate(380deg);
    }
  }

    &::before {
      content: '';
      position: absolute;
      height: 200vh;
      left: calc(-15vw);
      width: 115vw;
      background: url('../images/ready/bg01-1920w.webp');
      background-position: 0;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -10;

      @media (max-width: 650px) {
        background: url('../images/ready/bg01-1280w.webp');
        background-position: 0 0;
        background-size: contain;
        background-repeat: no-repeat;
        height: 270vh;
        top: -10vh;
        left: -85vw;
        width: 185vw;
      }
    }

    .section__content-1st {
      justify-content: flex-start;
    }

    .section__content-2nd {
      justify-content: flex-start;
      padding-top: clamp(1rem,2.5vw,3rem);
      align-items: flex-start;
    }

    .section__title {
      text-align: left;
    }
    
    &__button-container {
      width: auto;
      float: left;
    }

    .icon {
      font-family: fontello;
      font-style: normal;
      font-weight: 400;
      speak: never;
      display: inline-block;
      font-variant: normal;
    }

    &__date,
    &__place {
      font-family: $font-display;
      display: flex;
    }

    &__date {
      font-size: clamp(1.5rem, 2.5vw,  2.5rem);
      margin-bottom:1rem;
    }

    &__place {
      font-size: clamp(1.3rem, 2.3vw,  2rem);
      margin-bottom:2rem;
    }

    .icon {
      width: clamp(2.75rem, 3.75vw, 3.75rem);
      text-align: center;
    }

    @media (max-width: 768px) {

      

      &__button-container {
        display: flex;
        justify-content: center;
        width:100%;
      }

      .section__content-1st {
        justify-content: center;
        padding-bottom: 0;

        .section__subtitle {
          margin-bottom: .5rem;
        }

      }
  
      .section__content-2nd {
        justify-content: center;
        align-items: center;
      }
      .section__title {
        text-align: center;
      }

      &__date,
      &__place {
        justify-content: center;
      }

      &__place {
        font-size: clamp(1.1rem, 2.3vw,  2rem);
      }
    }

 
}






.calendar--container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  max-width: 30rem;
  padding: clamp(1rem, 2.5vw, 3rem);

  border: 1px solid #878787;
  background-color: #000;
  border-radius: 28px;
  @include box-shadow();

  min-width: calc(14rem + clamp(1rem, 2.5vw, 3rem));

  @media (max-width: 768px) {
    width: 75%;
    min-width: calc(10rem + clamp(1rem, 2.5vw, 3rem));
  }

}

.calendar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 14rem;
}

.day {
    width: calc(100% / 7);
    min-width: 2rem;
    padding: clamp(.05rem,2vw,.75rem) .2rem;
    text-align: center;
    font-size: clamp(.8rem,2.25vw,1.4rem);

    &--out,
    &--name {
      color: #656565;
    }

    &--selected {
      border: 3px solid $color-secondary;
      padding: clamp(.05rem,2vw,.75rem) calc(.2rem - 6px);

    }

}

.week {
  display: flex;
  flex-direction: row;
  width:100%;
}