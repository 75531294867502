$base-duration: 0.5s;
$base-distance: 50px;
$base-scale: 0.85;
$easing: ease-out;

// Mixins
@mixin transition($properties...) {
  transition-property: $properties;
  transition-duration: $base-duration;
  transition-timing-function: $easing;
}

@mixin transform($transform) {
  transform: $transform;
}

// Base animation class
.ani {
  opacity: 0;
  @include transition(opacity, transform);

  &.in-view {
    opacity: 1;
    @include transform(none);
  }

  &.ani--reset {
    transition: none !important;
  }

  // Outro state (applied via JS)
  &.ani--outro {
    opacity: 1;
  }

  &.out-view {
    opacity: 0;
  }
}

// Animation variants
.ani-fadeUp {
  @include transform(translateY($base-distance));
}

.ani-fadeDown {
  @include transform(translateY(-$base-distance));
}

.ani-fadeLeft {
  @include transform(translateX($base-distance));
}

.ani-fadeRight {
  @include transform(translateX(-$base-distance));
}

.ani-zoomIn {
  @include transform(scale($base-scale));
}

.ani-zoomOut {
  @include transform(scale(2 - $base-scale));
}

.ani-flipX {
  @include transform(rotateY(90deg));
}

.ani-flipY {
  @include transform(rotateX(90deg));
}

// Outro transforms (applied via JS)
.ani--outro {
  &.ani-fadeUp {
    @include transform(translateY(-$base-distance));
  }

  &.ani-fadeDown {
    @include transform(translateY($base-distance));
  }

  &.ani-fadeLeft {
    @include transform(translateX(-$base-distance));
  }

  &.ani-fadeRight {
    @include transform(translateX($base-distance));
  }

  &.ani-zoomIn {
    @include transform(scale(2 - $base-scale));
  }

  &.ani-zoomOut {
    @include transform(scale($base-scale));
  }

  &.ani-flipX {
    @include transform(rotateY(-90deg));
  }

  &.ani-flipY {
    @include transform(rotateX(-90deg));
  }
}

// Delays
@for $i from 1 through 10 {
  .ani--delay-#{$i} {
    transition-delay: $i * 0.1s;
  }
  .ani--delay-#{$i}-5 {
    transition-delay: $i * 0.1s + 0.05s;
  }
}


@media (max-width: 768px) {
  // Delays for mobile
  @for $i from 0 through 10 {
    .ani-xs--delay-#{$i} {
      transition-delay: $i * 0.1s;
    }
  }
}

// Speeds (inverse of duration)
$speeds: (
  'slow': 0.9s,
  'fast': 0.3s,
  'veryFast': 0.1s
);

@each $name, $duration in $speeds {
  .ani--speed-#{$name} {
    transition-duration: $duration;
  }
}

// Easings
$easings: (
  'linear': linear,
  'easeIn': ease-in,
  'easeOut': ease-out,
  'easeInOut': ease-in-out,
  'bounce': cubic-bezier(0.68, -0.55, 0.265, 1.55)
);

@each $name, $easing in $easings {
  .ani--easing-#{$name} {
    transition-timing-function: $easing;
  }
}