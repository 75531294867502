
.video-container {
    position: relative;
    width: 85%;
    padding-top: 47.8125%; /* 16:9 Aspect Ratio */
    z-index:15;
    margin: 0 7.5%;
    
}

.video-player {
    box-shadow: 0 8px 15px 3px rgba(0,0,0,1);
}

.play-button, 
.loading-animation, 
.video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play-button svg, 
.loading-animation svg {
    width: 165px;
    height: 165px;
    cursor: pointer;
}

.play-button {
svg {
    transition: transform 0.3s ease;
}

&:hover svg {
    transform: scale(1.1);
}

circle {
    transition: stroke-width 0.3s ease;
}

&:hover circle {
    stroke-width: 7;
}
}

.loading-animation {
.loading-circle {
    stroke-dasharray: 189;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1s linear infinite;
}
}

@keyframes dash {
0% {
    stroke-dashoffset: 189;
}
50% {
    stroke-dashoffset: 47.25;
    transform: rotate(135deg);
}
100% {
    stroke-dashoffset: 189;
    transform: rotate(450deg);
}
}

.video-wrapper {
    display: none;
}

#video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} 

@media (max-width: 768px) {

    .video-container {
      width: 100%;
      padding-top: 55.8125%; /* 16:9 Aspect Ratio */
      margin: 0;
    }
}  
