.movie {
  background-image: url('../images/movie.webp?width=2080&height=1120');
  background-size: cover;
  background-position: center;
  position: relative;
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 20vh;
  }

  &::before {
    top: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 16 / 9;
  }
}


@media (max-width: 768px) {

  .movie {
    height: 65vh;
    padding: 50px 0;
    background-image: url('../images/movie.webp?width=1000&height=1280');
  }

  .loading-animation svg {
    width: 100px;
    height: 100px;
  }

  .play-button svg {
    width: 80px;
    height: 80px;
  }

}