.footer {
    background: #000;

    @media (min-width: 768px) {
        padding-bottom: 45vh;
        background: linear-gradient(0deg, rgba(0,18,46,1) 0%, rgba(0,0,0,1) 100%);

    }

    .container {
        padding: 0 20px;
        max-width: 1680px;
    }

    &__container {
        display: flex;
        flex-direction: left;
        width: 100%;

        @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
            justify-content: center;
        }
    }

    &__logo {
        margin-bottom: 2rem;
        font-size: 4rem;
        font-family: $font-display;
        letter-spacing: 2px;
        @media (max-width: 768px) {
            text-align: center;
        }
    }

    &__text {
        margin-bottom: 2rem;
        font-size: clamp(1.1rem, 1.55vw,  1.35rem);
        line-height: 1.75em;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;

    }

    &__right {
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
        padding-left: 10rem;

        @media (max-width: 768px) {
            padding-left: 0;  

        }


    }


    &__social {
        display: flex;
        flex-direction: row;
        margin-left: -1rem;

        @media (max-width: 768px) {
            justify-content: center;
        }
    }

    &__social-icon {
        text-decoration: none;
        font-size: 3.5rem;
        transition: transform 0.1s linear;

        @media (max-width: 768px) {
            font-size: 2.75rem;
        }

        @media (min-width: 768px) {
            &:hover {
                transform: scale(0.85);
            }
        }

    }


    &__subtitle {
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            margin-top: 1.5rem;
        }
    }

    &__mail-item {
        margin-bottom: 1.25rem;

        @media (max-width: 768px) {
            text-align: Center;
        }

    }

    &__mail-desc {
        color: #a5a5a5;
        padding-left: 1rem;
    }

    &__bottom-strip {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 2rem 0;
        padding: 2rem 0;
        border-top: 1px solid #fff;

        @media (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__copyrights {
        text-align: center;
    }

    &__strip-links {
        
        display: flex;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
        }
    }

    &__strip-link {
        padding: 0.5rem;
        text-align:center;
    }


} 
    