.events {

    .mobile-only {
        display: none;
    }


    &::before {
        content: '';
        position: absolute;
        height: 200vh;
        left: calc(-15vw);
        width: 115vw;
        background: url('../images/ready/bg02-1920w.webp');
        background-position: 0;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -10;
        mix-blend-mode: lighten;
  
        @media (max-width: 650px) {
          background: url('../images/ready/bg02-1280w.webp');
          background-position: 0 0;
          background-size: contain;
          background-repeat: no-repeat;
          height: 270vh;
          top: -5vh;
          left: -85vw;
          width: 200vw;
        }
      }
  

    &__event {
        display: flex;
        align-items: center;
        padding: .5rem;
        justify-content: center;
    }

    &__year {
        font-family: $font-main;
        font-size: clamp(1.3rem, 2.3vw, 2rem);
        width: 5%;
        @media (max-width: 1200px) { 
            width: 100%;
            text-align: center;
        }
    }

    &__date {
        font-family: $font-display;
        font-size: clamp(1.3rem, 2.3vw, 2rem);
        width: 15.5%;
        @media (max-width: 1200px) { 
            width: 100%;
        }
    }

    &__event_together {
        width: 50%;
        @media (max-width: 1200px) { 
            width: 100%;
        }
    }

    &__button {
        min-width: 160px;
    }

    &__button-soon {
        cursor: auto !important;

    }

    &__name {
        font-family: $font-display;
        font-size: clamp(1.3rem, 2.3vw, 2rem);
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 63%;

        @media (max-width: 1200px) { 
            text-align: center;
        }
        
    }

    &__subname {
        font-family: $font-display;
        font-size: clamp(1.1rem, 2.2vw, 1.4rem);
        text-align: right;

        @media (max-width: 1200px) { 
            text-align: center;
        }

    }

    &__place {
        font-family: $font-main;
        text-transform: uppercase;
        margin-left: 1rem;
        font-size: clamp(1rem, 1.75vw, 1.3rem);
        color: $color-gray;
        width: 30%;
        @media (max-width: 1200px) { 
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: 1200px) {


        .desktop-only {
            display: none;
        }
        .mobile-only {
            display: flex;
            margin-right: 1rem;
        }

        &__place {
            margin-left: 0;
        }

        .flex {
            flex-direction: column;
            align-content: center;
            align-items: center;
            justify-content: center;
        }
    }
}