.aftermovie {

    .video-container {
        background-image: url('../images/aftermovie.webp?width=2080&height=1120');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        padding-top: 55.8125%; /* 16:9 Aspect Ratio */
        margin: 0;
        box-shadow: 0 35px 25px rgba(0,0,0,0.9)
    }


    @media (max-width: 768px) {
        .video-container {
            background-image: url('../images/movie.webp?width=1280&height=720');
        }
    }



    &--bubble-container {
        position: absolute;
        height: 600px;
        right: -360px;
        bottom: -240px;
        width: 600px;
        z-index: -1;

        @media (max-width: 650px) {
            bottom: unset;
            bottom: -100px;
            height: 250px;
            right: 30px;
            width: 250px;
          }
      }
    
      &--bubble {
        background-image: url('../images/ready/fluid03_1000px.webp');
        background-size: cover;
        height: 100%;
        width: 100%;
        transform: rotate(-160deg);
        @media (max-width: 650px) {
            background-image: url('../images/ready/fluid03_500px.webp');
        }
      }
    
    
      &--bubble-container-2 {
        height: 270px;
        position: absolute;
        right: -430px;
        top: 50px;
        width: 270px;
    
        @media (max-width: 650px) {
            top: unset;
            bottom: -150px;
            height: 100px;
            right: 10px;
            width: 100px;
        }
      }
    
      &--bubble-2 {
        background-image: url('../images/ready/fluid03_1000px.webp');
        background-size: cover;
        height: 100%;
        width: 100%;
        transform: rotate(-160deg);

        @media (max-width: 650px) {
            background-image: url('../images/ready/fluid03_500px.webp');
        }
      }
}