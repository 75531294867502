.cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  padding: 20px;
  z-index: 9999;
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  &__content {
    max-width: 1680px;
    margin: 0 auto;
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 15px;
    font-size: 0.9rem;
  }

  &__link {
    color: #60c565;
    text-decoration: underline;
    font-weight: 600;

    &:hover {
      color: #65df6b;
    }
  }

  &__options {
    margin-bottom: 25px;
  }

  &__option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 0.9rem;

    input[type="checkbox"] {
      display: none;
    }

    .checkbox-custom {
      width: 18px;
      height: 18px;
      border: 2px solid #fff;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      margin-right: 10px;
      transition: all 0.3s ease;

      &::after {
        content: '';
        position: absolute;
        display: none;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        height: 7px;
        left: 4px;
        top: 1px;
        width: 4px;
      }
    }

    input[type="checkbox"]:checked + .checkbox-custom {
      background-color: #4CAF50;
      border-color: #4CAF50;

      &::after {
        display: block;
      }
    }

    input[type="checkbox"]:disabled + .checkbox-custom {
      background-color: #888;
      border-color: #888;
      cursor: not-allowed;

      &::after {
        display: block;
        border-color: #fff;
      }
    }
  }

  &__buttons {
    display: flex;

    justify-content: flex-start;
      @media (max-width: 768px) {
        justify-content: space-between;
      }
  }

  &__button {
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    font-size: 0.9rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
      padding: 10px 10px;
    }

    &--accept {
      background-color: rgba(0,0,0,0.7);
      margin-right: 10px;

      background-color: rgba(0,0,0,0.7);
      color: #6CC66C;
      border: 2px solid #6CC66C;

      &:hover {
        background-color: #fff;
        border: 2px solid #fff;
        color: #000;
      }

    }

    &--save {
      background-color: rgba(0,0,0,0.7);
      color: #fff;
      border: 2px solid #fff;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}