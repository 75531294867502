
// Reset
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }
  
  body {
    font-family: $font-main;
    background-color: $color-background;
    color: $color-text;
  }

  .page {
    overflow: hidden;
  }
  
  .hero__title,
  .header__logo {
    font-family: $font-display;
  }
  
  @media (max-width: 420px) {
    .container {
      padding: 0 10px;
    }
  }
  
  img {
    width: 100%;
  }
  
  a {
    color: $color-secondary;
    text-decoration: none;
    transition: all 0.1s linear;
  
    &:visited,
    &:active {
        color: $color-secondary;
    }
  
    &:hover {
        color: #fff;
    }
  }
  
  .link-reverse {
    color: #fff; 
    text-decoration: none;
    transition: all 0.1s linear;
  
    &:visited,
    &:active {
      color: #fff; 
    }
  
    &:hover {
        color: $color-secondary;
    }
  }


  .container {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
  }
  



  // Utility classes
  .flex {
    display: flex;

    &--row {
      flex-direction: row;
    }
  
    &--sides {
      flex-direction: row;
      align-items: stretch;
    }

    &--wrap {
      flex-wrap: wrap;
    }

    &--between {
      justify-content: space-between;
    }
  
    &--center {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .nowrap {
    white-space: nowrap;
  }
  
// Buttons
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: $font-main;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.2;
  min-width: 120px; // Add this line to set a minimum width for buttons
  font-weight: 600;

  &--primary {
      @include button-style($color-secondary, $color-brightest, $color-background);
  }

  &--secondary {
      background-color: rgba(0,0,0,0.5);
      color: $color-text;
      border: 1px solid rgb(255 255 255 / 0%);

      &:visited,
      &:active {
          color: $color-text;
      }

      &:hover {
          background-color: $color-brightest;
          color: $color-text-dark;
      }
  }
}

// Add this new rule for consistent button sizing
.header__buttons {
  .button {
      min-width: 120px; // Adjust this value as needed
  }
}