.gallery-event {

    &__image-container {
        display:block;
        transition: opacity .3s ease, transform .1s linear;
        transform: scale(1);
        
        img {
        box-shadow: 0 35px 25px rgba(0, 0, 0, .9);
        }
        &:hover {
            transform: scale(0.95);

            img {
            box-shadow: 0 25px 25px rgba(0, 0, 0, .9);
            }
        }
    }
    @media (max-width: 768px) {
        .section__content-1st {
            padding-bottom: 50px;
        }
    }
    &__place {
        font-family: $font-main;
        font-size: clamp(1rem, 1.75vw, 1.3rem);
        color: $color-brightest;
        text-align: center;
        margin-top:2rem;
    }

    &__button-container {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:3rem;

        @media (max-width: 768px) {
            margin-top:2rem;
        }
    }
}