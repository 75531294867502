@import 'variables';


.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out;

  &--scrolled {
    backdrop-filter: blur(10px);
    background: linear-gradient(0deg, #0000004a, #000);
    box-shadow: 0 5px 15px #000c;
  }

  &__navbar {
    @include flex(row, space-between, center);
    height: $nav-height;
    padding: 0 20px;
    position: relative;
    z-index: 101;
  }

  &__logo {
    font-family: $font-display;
    color: $color-brightest;
    font-size: clamp(1.85rem, 5vw, 2.2rem);
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    color: $color-text;
    text-decoration: none;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    height: 100%;
    &:visited,
    &:active,
    &:hover {
      color: $color-brightest !important;
    }
  }

  &__nav {
    @include flex(row, center, center);
    list-style: none;
    margin: 0 auto;
    height: 100%;
  }

  &__nav-item {
    margin: 0 1rem;
    @include flex(row, center, center);
  }

  &__nav-link {
    text-decoration: none;
    color: $color-brightest;
    font-size: clamp(1rem, 1.25vw, 1.05rem);
    text-transform: uppercase;
    font-weight: 400;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -5px;
      left: 0;
      background-color: $color-brightest;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover::after,
    &--active::after {
      transform: scaleX(1);
    }

    &:visited,
    &:active,
    &:hover,
    &--active {
      color: $color-brightest;
    }
  }

  &__buttons {
    @include flex(row, center, center);
    gap: 1rem;
    height: 100%;
    padding: 0.5rem 0;

    .button {
      align-self: center;
      height: auto;
    }
  }

  &__mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background-color: rgba(0, 0, 0, 0.75);
      background: linear-gradient(333deg, rgba(0,0,0,0.9023984593837535) 0%, rgba(0,0,0,0.7931547619047619) 35%, rgba(0,0,0,0.804359243697479) 65%, rgba(0,0,0,0.896796218487395) 100%);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      opacity: 1;
      visibility: visible;
    }

    .mobile-menu-content {
      text-align: center;
      position: relative;
      opacity: 0;
      transform: translateY(20px);
      transition: all 0.4s ease-in-out;
      width: 100%;
    }

    .header__nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.6rem 0;
    }

    .header__nav-item {
      margin: 0.8rem 0;
    }

    .header__nav-link {
      font-size: 1.2rem;
    }
  }
}

.hamburger-container {
  display: none;
  align-items: center;
  
  &#headerHamburger {
    z-index: 102;
  }

  &#mobileMenuHamburger {
    position: fixed; 
    top: 0;
    right: 35px;
    height: $nav-height-mobile;
    z-index: 1002; 
    display: flex;
    align-items: center;
  }
}

.hamburger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  position: relative;
  
  &__line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $color-text;
    transition: all 0.3s ease-in-out;

    &:nth-child(1) { top: 0; }
    &:nth-child(2) { top: 11px; }
    &:nth-child(3) { top: 22px; }
  }

  &.active {
    .hamburger__line {
      &:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-11px) rotate(-45deg);
      }
    }
  }
}

.mobile-menu-logo {
  font-family: $font-display;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  color: $color-text;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;

  h2 {
    font-size: inherit;
    font-weight: normal;
  }
}

.mobile-menu-buttons {
  margin-top: 2rem;

  .button {
    margin: 0.5rem;
  }
}

@media (max-width: 1650px) {
  .header__nav-item {
    margin: 0 clamp(.5rem, 1vw, 2rem);
  }
}

@media (max-width: 1520px) {
  .hamburger-container {
    display: flex;
  }
  
  .header {
    &__navbar {
      height: $nav-height-mobile;
      padding: 0 15px;
    }

    &__nav {
      display: none;
    }

    &__buttons {
      margin-left: 0;
      margin-right: 1rem;
      height: $nav-height-mobile;
    }
  }

  .hamburger-container {
    display: flex;
  }
}

.mobile-menu {
  &.--over-halt {
    .ani--halt {
      animation-play-state: running !important;
      opacity: 1 !important;
      transform: none !important;
    }
  }
}

// Override for mobile menu
.mobile-menu.--over-halt {
  .ani-fade--down {
      opacity: 0;
      transform: translateY(-20px);
  }
  
  .ani-s--active.ani-fade--down {
      opacity: 1;
      transform: translateY(0);
  }
}


.lang-dropdown-container {
  display: flex;
  position: relative;
  height: 2.5rem;
  margin-right: 1rem;
  margin-left: auto;
  overflow: visible;
}



.mobile-lang {
  display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    list-style: none;
    list-style-type: none !important;

  &__item {
    margin: 0 1rem;

    &--active {
      .flag {
        opacity: 1;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
}


.lang-dropdown {

  display: flex;
  flex-direction: column;
  list-style: none;
  list-style-type: none !important;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: rgba(0,0,0,0.5);

  &:hover {

    border: 1px solid white;
    height: 5rem;

    .lang-dropdown__item {
      display: block;
    }
    
  }

  &__item {
    display: none;
    padding: 0.5rem;

    &--active {
      display: block;
    }
      
  }
}

.flag {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  text-decoration: none;
  color: $color-text;
  font-size: 1rem;
  padding-left: 2rem;
  padding-top: 0.2rem;
  position: relative;
  color: $color-brightest !important;
  text-transform: uppercase;
  opacity: 0.85;
  width: 3.6rem;

  &:not(.flag--active) {
    &:hover {
      opacity: 1;
      font-weight: 600;
      }
  }


  &::after {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
  }


  &--en {
    &::after {
      background-image: url('../images/ready/lang/en.svg');
    }
  }

  &--pl {
    &::after {
      background-image: url('../images/ready/lang/pl.svg');
    }
  }

}



@media (min-width: 1520px) {
  .lang-dropdown-container {
    margin-left: 1rem;
    margin-right: auto;
  }
}

@media (max-width: 830px) {
  .header {
    &__buttons {
      .button--secondary {
        display: none;
      }
    }
  }
}


@media (max-width: 650px) {

  .lang-dropdown {
    display: none;
  }

  .header {

    &__navbar {
      @include flex(row, space-between, center);
    }
    
    &__logo {
      font-size: clamp(1.6rem, 4vw, 2rem);
      letter-spacing: 0.3rem;
    }
  }

  .hamburger {
    margin-left: 10px;
  }
}

@media (max-width: 420px) {
  .header {
    &__buttons {
      display: none;
    }
    
    &__logo {
      font-size: clamp(1.4rem, 3.5vw, 1.8rem);
      letter-spacing: 0.2rem;
    }
  }

  .hamburger {
    margin-left: 5px;
  }
}
