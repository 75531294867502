.buy-tickets {
    padding-bottom:0;
    &__content {

        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 100%),
                    url('../images/festivaland_0100.webp?width=2080&height=1120');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 80%;
        width: 100%;
        padding-top:200px;
        padding-bottom:200px;
    }

    &__button-container {
        display:flex;
        justify-content: center;
    }

    .section__title {
        font-size: clamp(2.5rem, 5vw, 4rem);

    }
    .section__subtitle {
        margin-bottom: 4rem;
        font-weight: 400;
    }

    &__top-bars {
        background-image: url('../images/bars.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        border: 0;
        height: 16vh;
        position: absolute;
        z-index: -1;
        top: 0px;
        width: 100vw;

        @media (max-width: 1200px) {
            height: 135px;
            top: -15px;
        }
        
        @media (max-width: 750px) {
            top: -40px;
        }
        @media (max-width: 550px) {
            top: -35px;
        }
    }

    @media (max-width: 1200px) {
        &__content {
            padding: 8vh 0;
        }

        &__content {
            padding: 6vh 0;
        }
    } 


    &--bubble-container {
        position: absolute;
        z-index: 1;
        height: 600px;
        width: 600px;
        top: -450px;
        left: -25vw;

        @media (max-width: 768px) {
            left: 0;
            top: -200px;
            height: 200px;
            width: 200px;
        }
    
        @media (max-width: 480px) {
            height: 175px;
            left: -50px;
            top: -175px;
            width: 175px;
        }
      }

      &--bubble {
        background-image: url('../images/ready/fluid06_1220px.webp');
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        transform: rotate(-3deg);

        @media (max-width: 768px) {
            background-image: url('../images/ready/fluid06_610px.webp');
        }
      }

      &--bubble-container-2 {
        position: absolute;
        z-index: 1;
        height: 600px;
        width: 600px;
        bottom: -550px;
        right: -25vw;

        @media (max-width: 768px) {
            right: 0;
            bottom: -150px;
            height: 200px;
            width: 200px;
        }
    
        @media (max-width: 480px) {
            height: 175px;
            right: -50px;
            bottom: -130px;
            width: 175px;
        }
      }

      &--bubble-2 {
        background-image: url('../images/ready/fluid05_1040px.webp');
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        transform: rotate(-3deg);

        @media (max-width: 768px) {
            background-image: url('../images/ready/fluid05_520px.webp');
        }
      }

}