.section {

    padding: 100px 0;
    position: relative;

    &--first {
        padding: 150px 0 100px;
    }

    .container {
        max-width: 1200px;
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
    }

    &__content-1st,
    &__content-2nd {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 50%;
        width: 50%;
    }

    &__main-title {
        font-family: $font-display;
        font-size: 4rem;
        letter-spacing: 5px;
        line-height: 1;
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);

    }

    &__title {
        font-family: $font-display;
        font-size: clamp(1.65rem, 3.4vw,  2.85rem);
        letter-spacing: 5px;
        line-height: 1;
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    }

    &__subtitle {
        font-family: $font-display;
        font-size: clamp(1.2rem, 2.6vw,  1.65rem);
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    }
    
    &__small-title {
        font-family: $font-display;
        font-size: clamp(1.1rem, 2.3vw,  1.45rem);
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    &__list {
        margin-bottom: 4rem;
    }

    &__list-title {
        font-family: $font-display;
        font-size: clamp(1.1rem, 2.3vw,  1.45rem);
        margin-bottom: 20px;
        text-align: left;
        letter-spacing: 2px;
    }

    &__text {
        font-size: 1rem;
        line-height: 1.55em;
        margin-bottom: 30px;
    }


    @media (max-width: 1300px) {  
        &__content-2nd {
        padding: 0 2.5vw;
        }

    }
        
    @media (max-width: 768px) {
        background-size: 100% auto;
        padding: 50px 0;

        &--first {
            padding: 100px 0 50px;
        }

        .container {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        &__title {
            font-size: clamp(1.45rem, 3.4vw,  2.85rem);
        }
    
        &__subtitle {
            font-size: clamp(1.2rem, 2.6vw,  1.65rem);
        }
        
        &__small-title {
            font-size: clamp(1.1rem, 2.3vw,  1.45rem);
        }

        &__text {
            font-size: .9remrem;
            line-height: 1.45em;
        }

        &__content-1st,
        &__content-2nd {
            padding-bottom: 25px;
        }

        &__content-1st,
        &__content-2nd {
            flex-basis: 100%;
        }

        &__content-2nd {
            padding: 0 5vw;
        }
    }
}

