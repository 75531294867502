// Variables
$nav-height: 80px;
$nav-height-mobile: 65px;
$color-primary: #2e97ff;
$color-secondary: #fe2400;
$color-brightest: #fff;
$color-text: #fff;
$color-gray: #9c9c9c;
$color-text-dark: #000;
$color-background: #000;

$font-main: 'Montserrat', sans-serif;
$font-display: 'Climate Crisis', sans-serif;

// Mixins
@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin box-shadow() {
  box-shadow: 0 25px 20px 3px rgba(#000, 0.6);
}

@mixin button-style($bg-color, $text-color, $shadow-color) {
  background-color: $bg-color;
  color: $text-color;
  box-shadow: 0 0px 15px 3px rgba($shadow-color, 0.6);
  transform: scale(1);
  transition: all 0.3s ease;
  text-shadow: 0 1px 1px #000;
  
  &:hover {
    background-color: $color-text;
    color: $color-text-dark;
    box-shadow: 0 6px 8px rgba($shadow-color, 0.4);
    text-shadow: 0 1px 1px #fff;
    transform: scale(1.1);
  }
}