.music {
    &--bubble-container {
        position: absolute;
        z-index: -1;
        height: 400px;
        width: 400px;
        top: -250px;
        right: -250px;

        @media (max-width: 768px) {
            right: 0;
            top: -150px;
            height: 250px;
            width: 250px;
        }
    
        @media (max-width: 480px) {
            height: 200px;
            right: -30px;
            top: -150px;
            width: 200px;
        }
      }

      &--bubble {
        background-image: url('../images/ready/fluid04_800px.webp');
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        transform: rotate(-3deg);
      }
    
}

