.the-m {
  color: $color-text;
  padding: 5vh 0;

  a {
    color: $color-secondary;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

    &__content-1st,
    &__content-2nd {
      flex-basis: 48%;
    }

    &__content-1st {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }


    &__title {
      font-family: $font-display;
      font-size: 6rem;
      letter-spacing: 5px;
      line-height: 1;
      margin-bottom: 10px;
      text-align: center;
    }

    &__subtitle {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: center;
    }

    &__text {
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 0;
    }

    .section__text {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      background-size: 100% auto;
      padding: 50px 0;
    }
    
        
    @media (max-width: 768px) {
        background-size: 100% auto;
        padding: 50px 0;

        &__title {
        font-size: 4rem;
        }
    }
}