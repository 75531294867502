.gallery-header {
    padding-bottom: 0;
    &::before {
        content: '';
        position: absolute;
        height: 200vh;
        left: calc(-15vw);
        width: 115vw;
        background: url('../images/ready/bg01-1920w.webp');
        background-position: 0;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -10;
  
        @media (max-width: 650px) {
          background: url('../images/ready/bg01-1280w.webp');
          background-position: 0 0;
          background-size: contain;
          background-repeat: no-repeat;
          height: 270vh;
          top: -10vh;
          left: -85vw;
          width: 185vw;
        }
    }

    &__date {
        font-weight: 300;
        font-family: $font-main;
        font-size: clamp(1.2rem, 1.75vw, 1.5rem);
        color: $color-gray;
    }

    &__event-name {
        display: flex;
        align-content: center;
    }

    .events__year {
        width: 10%;
    }
    .events__date {
        width: 15%;
    }
    
    .events__event_together {
        width: 590px;
    }

    .container {
        padding-bottom: 0; 
        max-width: 1000px;

        
        @media (max-width: 768px) {
            flex-direction: column;
            align-content: center;
            align-items: center;
        }
    }

    &--bubble-container {
        position: absolute;
        z-index: 1;
        height: 500px;
        width: 500px;
        top: -110px;
        right: -25vw;

        @media (max-width: 768px) {
            right: 0;
            top: -50px;
            height: 200px;
            width: 200px;
        }
    
        @media (max-width: 480px) {
            height: 200px;
            right: -89px;
            top: 70px;
            width: 200px;
        }
      }

      &--bubble {
        background-image: url('../images/ready/fluid06_610px.webp');
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        transform: rotate(-3deg);
      }
}


.gallery {

    &__container {
        padding-top: 50px;
    }


    &__content {
        //column-count: 4;
        column-gap: 10px;
        padding: 20px;
        background: #000;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: masonry;

        @media (max-width: 1268px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }

    }

    &__image {
        position: relative;
        break-inside: avoid;
        margin-bottom: 10px;
        display: block;
        cursor: zoom-in;
        border: 0 solid #fff;
        transition: .1s all linear;

        @media (min-width: 768px) {
            aspect-ratio: 13.5 / 9;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:hover {
                border: 8px solid #fff;
            }

        }

        &--up {
            justify-content: flex-start;
        }

        &.loaded img {
            opacity: 1;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
            opacity: 0;
        }
    }

    &-modal {
        display: none;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: rgba(0,0,0,0.9);
        justify-content: center;
        align-items: center;
    }
}

.gallery-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.9);
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 75px;
    backdrop-filter: blur(10px);
}

.modal-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.modal-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease-out;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
}

.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 2rem;
    font-family: "fontello", Arial;
    border: 2px solid #fff;
    border-radius: 50%;
    z-index: 1001;
    transition: background-color 0.3s ease;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.prev { left: 20px; }
.next { right: 20px; }

.prev:hover, .next:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.prev::before {
    content: '\e800';
}

.next::before {
    content: '\e803';
}

@media (max-width: 768px) {
    .gallery__image img {
        pointer-events: none;
    }
    .gallery-modal {
        display: none !important;
    }
}

.preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    transition: opacity 0.3s ease-out;
}

@keyframes spin {
    to { transform: translate(-50%, -50%) rotate(360deg); }
}

.gallery__image.loaded .preloader {
    opacity: 0;
}



.download-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    border-radius: 50%;
    display: none;
    transition: all 1s ease;
    font-size: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    justify-content: center;
    align-items: center;
    transform: scale(0.5);
}

.download-icon:hover {
    opacity: 1;
}

.gallery__image:hover .download-icon {
    display: flex;
    transform: scale(1);
}


