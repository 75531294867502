// At the beginning of your main.scss file
$fontello-font-path: '../fonts/fontello/';

@import 'fontello/fontello'; 
@import 'variables';
@import 'header';
@import 'base';
@import 'sections';
@import 'video-player';
@import 'carousel';
@import 'hero';
@import 'movie';
@import 'the-m';
@import 'animation';
@import 'gallery-strip';
@import 'next-event';
@import 'aftermovie';
@import 'events';
@import 'music';
@import 'buy-tickets';
@import 'footer';
@import 'cookie-notice';
@import 'gallery-event';
@import 'gallery';

// remove it from fontello/fontello.css after every export!
@font-face {
  font-family: 'fontello';
  src: url('#{$fontello-font-path}fontello.eot?22427577');
  src: url('#{$fontello-font-path}fontello.eot?22427577#iefix') format('embedded-opentype'),
       url('#{$fontello-font-path}fontello.woff2?22427577') format('woff2'),
       url('#{$fontello-font-path}fontello.woff?22427577') format('woff'),
       url('#{$fontello-font-path}fontello.ttf?22427577') format('truetype'),
       url('#{$fontello-font-path}fontello.svg?22427577#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

